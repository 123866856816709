<template>
  <div>
    <van-nav-bar
      class="backBox"
      title="我的服务"
      right-text=""
      left-arrow
      @click-left="onClickLeft"
    />

    <div class="page">
      <div class="mainWrap">
        <div class="list_box">
              <div
                class="group_recharge"
                v-for="item in parkingRecordList"
                :key="item.id"
              >
                <div class="itemBox">
                  <div class="mainBox">
                    <div class="mainBoxLeft">
                      <!-- <img
                        src="../../../assets/img/fapiaojilu2@2x.png"
                        class="mainBoxLeftIcon"
                      /> -->
                      <div class="mainBoxLeftCenter">
                        <div class="mainBoxLeftCenterNum">
                          <span>{{ item.plate_number }}</span>
                          <span class="tit">{{ item.vh_cate_name }}</span>
                          <span class="tit">{{ stateFormatter(item) }}</span>
                        </div>
                        <span class="mainBoxLeftCenterType">
                          {{ dateFormatter(item)
                          }}
                        </span>
                        <!-- <span class="mainBoxLeftCenterType">
                          {{ ruleTextFormatter(item) }}
                        </span> -->
                        <!-- <span decode="true" class="mainBoxLeftCenterDate">
                          <div class="circleInBox">
                            <span class="circleIn"></span>
                          </div>
                          <span class="date_tit"> 进场时间：</span>
                          {{ formattedDate(item.in_time) }}
                        </span> -->
                        <!-- <span decode="true" class="mainBoxLeftCenterDate">
                          <div class="circleOutBox">
                            <span class="circleOut"></span>
                          </div>
                          <span class="date_tit"> 出场时间：</span>
                          {{ formattedDate(item.out_time) }}
                        </span> -->
                      </div>
                    </div>
                    <!-- <div class="mainBoxRight">
                      <span class="mainBoxRightMoney">{{ item.money }}元</span>
                    </div> -->
                  </div>
                  <!-- <div
                    class="lineline"
                    v-if="parkingRecordList.length - 1 !== index"
                  ></div> -->
                </div>
              </div>
          <!-- 空状态 -->
          <van-empty
            v-if="parkingRecordList.length == 0"
            description="暂无匹配数据"
          />
          <van-empty
            v-if="parkingRecordList.length !== 0 && isError"
            image="error"
            description="查询异常"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import DateSelect from '../../../components/DateSelect.vue'
// import Pagination from '../../../components/Pagination.vue'
import * as common from '../../../api/common'
import * as api from '../../../api/api'
// import { Toast } from 'vant'
import { mapState, mapGetters } from 'vuex'
import { Toast } from 'vant'
// import moment from 'moment'
export default {
  // components: { DateSelect, Pagination },
  components: { DateSelect },
  data () {
    return {
      tokenInfoObj: {},
      active: 0,

      show: false,

      objectArray: [
        {
          id: 0,
          money: '22',
          groupName: '银湖分组',
          date: '2022/12/12 12:12:12',
          src: '../../../assets/img/rightjt.png',
          plateNum: '浙A88899'
        },
        {
          id: 1,
          money: '25',
          groupName: '银湖分组2',
          date: '2023-11-11 13:13:13',
          src: '../../../assets/img/rightjt.png',
          plateNum: '浙A88899'
        },
        {
          id: 2,
          money: '25',
          groupName: '银湖分组2',
          date: '2023-11-11 13:13',
          src: '../../../assets/img/rightjt.png',
          plateNum: '浙A88899'
        }
      ],
      objectArray2: [
        {
          id: 0,
          money: '22',
          groupName: '银湖缴费分组',
          date: '2022-12-12 12:12',
          src: '../../../assets/img/rightjt.png'
        },
        {
          id: 1,
          money: '25',
          groupName: '银湖缴费分组',
          date: '2023-11-11 13:13',
          src: '../../../assets/img/rightjt.png'
        },
        {
          id: 2,
          money: '25',
          groupName: '银湖缴费分组',
          date: '2023-11-11 13:13',
          src: '../../../assets/img/rightjt.png'
        }
      ],
      // vhColumns: ['全部', '浙A12345', '中国', '巴西', '日本'],
      vhColumnsLocal: [],
      index: 0,
      array2: ['今天', '明天', '前天', '巴西', '日本'],
      index2: 0,
      recentDates: '',
      lastAmonth: '',
      lastThreeParking: '',
      plateNum: '',
      user_info: {},
      token: '',
      parkPayRecordList: [
        {
          id: 0,
          money: '22',
          groupName: '银湖分组',
          date: '2022-12-12 12:12:12',
          src: '../../../assets/img/rightjt.png',
          plateNum: '浙A88899',
          parked_time: {
            seconds: '99小时'
          }
        },
        {
          id: 1,
          money: '25',
          groupName: '银湖分组2',
          date: '2023/11/11 13:13:13',
          in_time: '2023-11-11 13:13:13',
          out_time: '2023-11-11 13:13:!3',
          src: '../../../assets/img/rightjt.png',
          plateNum: '浙A88899',
          parked_time: {
            seconds: '99小时'
          }
        },
        {
          id: 2,
          money: '25',
          groupName: '银湖分组2',
          date: '2023/11/11 13:13:13',
          in_time: '2023-11-11 13:13:13',
          out_time: '2023-11-11 13:13:!3',
          src: '../../../assets/img/rightjt.png',
          plateNum: '浙A88899',
          parked_time: {
            seconds: '99小时'
          }
        },
        {
          id: 3,
          money: '25',
          groupName: '银湖分组2',
          date: '2023/11/11 13:13:13',
          in_time: '2023-11-11 13:13:13',
          out_time: '2023-11-11 13:13:!3',
          src: '../../../assets/img/rightjt.png',
          plateNum: '浙A88899',
          parked_time: {
            seconds: '99小时'
          }
        },
        {
          id: 4,
          money: '25',
          groupName: '银湖分组2',
          date: '2023/11/11 13:13:13',
          in_time: '2023-11-11 13:13:13',
          out_time: '2023-11-11 13:13:!3',
          src: '../../../assets/img/rightjt.png',
          plateNum: '浙A88899',
          parked_time: {
            seconds: '99小时'
          }
        },
        {
          id: 5,
          money: '25',
          groupName: '银湖分组2',
          date: '2023/11/11 13:13:13',
          in_time: '2023-11-11 13:13:13',
          out_time: '2023-11-11 13:13:!3',
          src: '../../../assets/img/rightjt.png',
          plateNum: '浙A88899',
          parked_time: {
            seconds: '99小时'
          }
        }
      ],
      parkingRecordList: [
        // {
        //   id: 0,
        //   money: '22',
        //   groupName: '银湖分组',
        //   in_time: '2022-12-12 12:12:12',
        //   out_time: '2022-12-14 12:12:12',
        //   src: '../../../assets/img/rightjt.png',
        //   plateNum: '浙A00001',
        //   parkName: '杭州停车场',
        //   parkTime: '2小时20分钟'
        // },
        // {
        //   id: 1,
        //   money: '22',
        //   groupName: '银湖分组',
        //   in_time: '2022-12-12 12:12:12',
        //   out_time: '2022-12-14 12:12:12',
        //   src: '../../../assets/img/rightjt.png',
        //   plateNum: '浙A00002',
        //   parkName: '江南停车场',
        //   parkTime: '2小时20分钟'
        // },
        // {
        //   id: 2,
        //   money: '22',
        //   groupName: '银湖分组',
        //   in_time: '2022-12-12 12:12:12',
        //   out_time: '2022-12-14 12:12:12',
        //   src: '../../../assets/img/rightjt.png',
        //   plateNum: '浙A00002',
        //   parkName: '江南停车场',
        //   parkTime: '2小时20分钟'
        // },
        // {
        //   id: 3,
        //   money: '22',
        //   groupName: '银湖分组',
        //   in_time: '2022-12-12 12:12:12',
        //   out_time: '2022-12-14 12:12:12',
        //   src: '../../../assets/img/rightjt.png',
        //   plateNum: '浙A00002',
        //   parkName: '江南停车场',
        //   parkTime: '2小时20分钟'
        // },
        // {
        //   id: 4,
        //   money: '22',
        //   groupName: '银湖分组',
        //   in_time: '2022-12-12 12:12:12',
        //   out_time: '2022-12-14 12:12:12',
        //   src: '../../../assets/img/rightjt.png',
        //   plateNum: '浙A00002',
        //   parkName: '江南停车场',
        //   parkTime: '2小时20分钟'
        // }
      ],
      isError: false,
      showVhPopup: false,
      selVhVal: '',
      showDatePopup: false,
      currentDateStartFormat: common.fmtDate(new Date(), 'yyyy-MM-dd'),
      currentDateEndFormat: common.fmtDate(new Date(), 'yyyy-MM-dd'),
      pageOption: {
        page_no: 1,
        page_size: 10,
        total: 0
      },
      // loading: false,
      // finished: false,
      // refreshing: false,
      // error: false,
      // errorFlag: false,
      loadingParking: false,
      finishedParking: false,
      refreshingParking: false,
      errorParking: false,
      errorFlagParking: false,
      parkName: '',
      qryFlag: false
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.queryParkingRecord()
    })
  },
  computed: {
    ...mapState({
      sid: (state) => state.sid.sid
    }),
    ...mapGetters(['vhColumns', 'openID'])
  },
  methods: {
    dateFormatter (item) {
      if (item.create_time && item.effective_date_end) {
        return common.fmtDate(item.create_time, 'yyyy-MM-dd') + ' 至 ' + common.fmtDate(item.effective_date_end, 'yyyy-MM-dd')
      }
    },
    stateFormatter (item) {
      let str = ''
      if (!item.create_time && !item.effective_date_end) return ''
      const now = new Date()
      if (now.getTime() < new Date(item.create_time).getTime()) {
        str = '未开始'
      } else if (now.getTime() > new Date(item.create_time).getTime() && now.getTime() < new Date(item.effective_date_end).getTime()) {
        str = '生效中'
      } else {
        str = '已过期'
      }
      return str
    },
    ruleTextFormatter (item) {
      if (item.id === 0) {
        return '包租时段内全时段生效'
      } else {
        return item.startTime + ' - ' + item.endTime
      }
    },
    // 根据sid查询车场信息
    query_customer_by_sid () {
      axios({
        url: common.cloudParkingUrl + '/s2p/' + api.urlList.query_customer,
        method: 'POST',
        data: {
          sid: this.sid
          // sid: 'yuen03'
        },
        header: {
          'content-type': 'application/json' // 默认值
        }
      }).then((res) => {
        const d = res && res.data
        if (d.code === 10000) {
          this.parkName = d.list[0].alias_name
          // 保存车场名称

          localStorage.setItem('park_name', this.parkName)
        }
      })
    },
    parkingTimeFormatter (item) {
      // {
      //   minutes: 12
      //   seconds: 48
      // }
      if (item.parked_time) {
        const { hours, minutes, seconds } = item.parked_time
        let timeStr = ''
        if (hours) {
          timeStr += `${hours}小时`
        }
        if (minutes) {
          timeStr += `${minutes}分钟`
        }
        if (seconds) {
          timeStr += `${seconds}秒`
        }
        return timeStr
      }
    },
    formattedDate (dateString) {
      // const localDate = moment.utc(dateString).local()
      // return localDate.format('YYYY/MM/DD HH:mm:ss')
      return dateString.replace(/-/g, '/') //
    },
    formatDate (date) {
      return common.fmtDate(date, 'yyyyMM/dd hh:mm:ss')
    },
    // 查询停车记录
    queryParkingRecord () {
      // TODO 本地开发需要
      // this.selVhVal = '浙AQW001'
      Toast.loading({
        forbidClick: true,
        message: '请稍候...'
      })
      try {
        // TODO 本地开发需要注释；正式发布取消注释
        if (!this.sid) {
          this.$toast.fail('车场编码丢失，请返回首页重试或者重新扫码进入！')
          return
        }
        // todo 参数完善
        // todo 微信授权之后应该换取token保存相应的userinfo信息 包含 可用的customer_id sid
        axios({
          // url: common.serverName + api.urlList.parkRecord.park,
          // url: 'http://192.168.1.187:7990' + api.urlList.parkRecord.park,

          url: common.iotUrl + '/uuid/' + api.urlList.car_owner.vh.get_bind_rent_vh,
          method: 'POST',
          data: {
            version: 'v2',
            access_token: 'CA502D23-BB70-0001-55FA-1B501C501A6B',
            app_key: '666a659e-244d-46b1-a8cc-3c5190c3823a',
            sid: this.sid,
            // sid: 'YX45SX',
            openid: this.openID
            // openid: 'o1pxwuMcjjXt2YtCXSl3UC-ZbK-w'
            // sid: 'yuen03',
            // vh_p_number_list: vhNumList,
            // page_size: this.pageOption.page_size,
            // page_no: this.pageOption.page_no,

          },
          header: {
            'content-type': 'application/json' // 默认值
          }
        })
          .then((res) => {
            console.log(res.data)
            Toast.clear()
            if (res.status >= 200 && res.status < 300) {
              if (res.data.code === common.resCode.OK) {
                console.log('查询我已绑定的长租车列表数据', res.data.list)

                this.pageOption.total = res.data.total
                this.parkingRecordList = res.data.list

                // if (this.refreshingParking || this.qryFlag) {
                //   console.log()
                //   this.parkingRecordList = res.data.list

                //   this.qryFlag = false
                //   this.loadingParking = false
                //   this.refreshingParking = false
                // } else {
                //   this.parkingRecordList = [
                //     ...this.parkingRecordList,
                //     ...res.data.list
                //   ]
                //   this.loadingParking = false
                //   if (this.parkingRecordList.length >= res.data.total) {
                //     this.finishedParking = true
                //   }
                // }
              }
            }
          })
          .catch((e) => {
            console.log(e)
          })
      } catch (e) {
        console.log('error', e)
      }
    },

    onClickLeft () {
      this.$router.go(-1)
    },
    onRefreshParking () {
      console.log('正在刷新', this.refreshingParking)
      this.parkingRecordList = []
      this.finishedParking = false
      this.loadingParking = true
      this.onLoadParking()
    },
    onLoadParking () {
      // 监听到即执行
      console.log('滑到底了')
      //   再次调用 查询 下一页数据
      if (this.refreshingParking) {
        // 下拉刷新
        this.pageOption.page_no = 1
        this.queryParkingRecord()
      } else {
        if (this.errorFlagParking) {
          // 说明上次加载失败 现在重新加载
          this.pageOption.page_no = 1
          this.errorFlagParking = false
          this.queryParkingRecord()
          return false
        }
        // 触底加载
        if (this.parkingRecordList.length >= this.pageOption.total) {
          this.finishedParking = true
        } else {
          this.pageOption.page_no = this.pageOption.page_no + 1
          this.queryParkingRecord()
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.page {
}
.mainWrap {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f8f7fc;
  height: 100vh;
  //   padding: 0.16rem 0;
  // overflow: hidden;
  .rightIcon {
    width: 0.08rem;
    height: 0.14rem;
    margin: 0.03rem 0 0.03rem 0.05rem;
  }
  .lineline {
    height: 0.01rem;
    background-color: #e2e2e2;
    margin-top: 0.07rem;
    margin-bottom: 0.07rem;
  }
  .query_box {
    width: 3.43rem;
    // height: 5rem;
    display: flex;
    flex-direction: column;
    .query_box_item {
      background-color: rgba(255, 255, 255, 1);
      position: relative;
      /* width: 686rpx; */
      // height: 2.5rem;
      flex-direction: row;
      display: flex;
      justify-content: space-between;
      padding: 0.09rem 0.16rem 0.09rem 0.16rem;
      .query_box_item_tit {
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 0.16rem;
        font-family: PingFangSC-Regular;
        font-weight: normal;
        text-align: left;
        white-space: nowrap;
        // line-height: 1.375rem;
      }
      .query_box_item_right {
        /* width: 84rpx; */
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        margin: 0.01rem 0 0.01rem 0;
        .section {
          display: flex;
          /* width: 90rpx; */
          justify-content: space-between;
          align-items: center;
          .selPlateNum {
            font-size: 0.14rem;
          }
        }
      }
    }

    .query_box_item {
      background-color: rgba(255, 255, 255, 1);
      position: relative;
      // height: 2.5rem;
      flex-direction: row;
      display: flex;
      justify-content: space-between;
      padding: 0.09rem 0.16rem;
      .query_box_item_tit {
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 0.16rem;
        font-family: PingFangSC-Regular;
        font-weight: normal;
        text-align: left;
        white-space: nowrap;
        // line-height: 1.375rem;
      }
      .query_box_item_right {
        /* width: 84rpx; */
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        margin: 0.01rem 0;
        .section {
          display: flex;
          /* width: 90rpx; */
          justify-content: space-between;
          align-items: center;
          .showCommon {
            font-size: 0.14rem;
          }
          .rightIcon {
            width: 0.08rem;
            height: 0.14rem;
            margin: 0.03rem 0 0.03rem 0.05rem;
          }
        }
        .image_4-1 {
          position: absolute;
          left: 0;
          top: -0.01rem;
          width: 3.42rem;
          height: 0.01rem;
          background-color: #f0f0f0;
        }
      }
    }
  }

  .list_box {
    // background-color: rgba(255, 255, 255, 1);
    // width: 3.43rem;
    width: 100%;
    align-self: center;
    margin-top: 0.16rem;
    display: flex;
    flex-direction: column;
    // padding: 0.08rem 0.01rem 0.01rem 0;
    // overflow-y: auto;
    .itemBox {
      //   width: 3.25rem;
      background-color: rgba(255, 255, 255, 1);

      flex-direction: column;
      display: flex;
      justify-content: space-between;
      //   margin: 0.04rem 0.07rem 0 0.1rem;
      margin-bottom: 0.16rem;
      padding: 0.16rem 0.16rem 0 0.16rem;
      .mainBox {
        // width: 3.25rem;
        // flex-direction: row;
        // display: flex;
        // justify-content: space-between;
        /* margin: 8rpx 14rpx 0 20rpx; */
        .mainBoxLeft {
          /* width: 280rpx; */
        //   flex-direction: row;
        //   display: flex;
        //   justify-content: space-between;
          .mainBoxLeftIcon {
            width: 0.2rem;
            height: 0.16rem;
            margin: 0.03rem 0 0.43rem 0;
          }
          .mainBoxLeftCenter {
            display: flex;
            flex-direction: column;
            // margin-left: 0.06rem;
            .mainBoxLeftCenterNum {
              overflow-wrap: break-word;
              color: #333;
              font-size: 0.16rem;
              font-family: PingFangSC-Regular;
              font-weight: normal;
              text-align: left;
              white-space: nowrap;
              line-height: 0.22rem;
              margin-right: 0.39rem;
              margin-bottom: 0.16rem;
              display: flex;
              justify-content: space-between;
            }
            .mainBoxLeftCenterType {
              overflow-wrap: break-word;
              color: #333;
              font-size: 0.14rem;
              font-family: PingFangSC-Regular;
              font-weight: normal;
              text-align: left;
              white-space: nowrap;
              line-height: 0.17rem;
              // margin: 0.04rem 0.55rem 0 0;
              margin-bottom: 0.16rem;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              .tit {
                margin-left: 0.12rem;
              }
            }

            .mainBoxLeftCenterDate {
              overflow-wrap: break-word;
              color: #333;
              font-size: 0.14rem;
              font-family: PingFangSC-Regular;
              font-weight: normal;
              text-align: left;
              white-space: nowrap;
              line-height: 0.17rem;
              // margin-top: 0.02rem;
              margin-bottom: 0.16rem;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              .circleInBox,
              .circleOutBox {
                width: 0.12rem;
                height: 0.12rem;
                display: flex;
                align-items: center;
                justify-content: center;
              }
              .circleIn,
              .circleOut {
                display: block;
                width: 0.05rem;
                height: 0.05rem;
                border-radius: 50%;
              }
              .circleIn {
                background-color: #00c099;
              }
              .circleOut {
                background-color: #fd0062;
              }
              .date_tit {
                color: #999;
                margin-left: 0.12rem;
              }
            }
          }
        }
        .mainBoxRight {
          margin-bottom: 0.19rem;
          display: flex;
          flex-direction: column;
          .mainBoxRightMoney {
            overflow-wrap: break-word;
            color: rgba(51, 51, 51, 1);
            font-size: 0.16rem;
            font-family: PingFangSC-Regular;
            font-weight: normal;
            text-align: left;
            white-space: nowrap;
            line-height: 0.22rem;
            margin-left: 0.17rem;
          }
        }
      }
    }
  }
}
</style>
